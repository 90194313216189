<template>
  <div class="induction">
    <!-- 待入职人员 -->
    <van-nav-bar
      title="待入职"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <!-- 使用 title 插槽来自定义标题 -->
    <div class="search">
      <form action="/">
        <van-search
          v-model="value"
          shape="round"
          placeholder="名字/职位"
          @search="onSearch"
        />
      </form>
    </div>

    <div class="person_cards" :style="{ height: height }">
      <div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <van-cell-group v-for="(item, index) in entryList" :key="index">
            <van-cell @click="showPersonalInf(item)">
              <template #title>
                <div class="cell_one">
                  <span>{{ item.empname }}</span>
                  <svg-icon icon-class="induction_link"></svg-icon>
                  <span>{{ item.staname }}</span>
                </div>
                <div class="cell_two">
                  <span>{{ item.telephone }}</span>
                  <svg-icon style="margin: 0 0.06667rem;" icon-class="interview_tel"></svg-icon>
                  <svg-icon style="margin: 0 0.06667rem;" icon-class="interview_wx"></svg-icon>
                </div>
                <div class="cell_three">
                  <span v-if="!isNaN(Number(item.workyear))">{{
                    `${item.workyear}年经验`
                  }}</span>
                  <span v-else>{{ item.workyear }}</span>
                  <span class="padding_sp">|</span>
                  <span>{{ item.degree }}</span>
                </div>
                <!-- <div class="cell_four">
                  <span>到岗时间：</span>
                  <span>2021-04-11 08:00</span>
                </div> -->
              </template>
              <template #default>
                <div class="head_card">
                  <svg-icon style="width:100%;height:100%;" icon-class="default_avator"  />
                </div>
                <van-button
                  plain
                  color="#2b8df0"
                  type="primary"
                  size="mini"
                  @click.stop="confirm(item)"
                  >确认入职</van-button
                >
              </template>
            </van-cell>
          </van-cell-group>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { getEntryList } from "@api/wxzp.js";
import { searchFunc } from '@/utils';
import { Toast } from 'vant';
export default {
  data() {
    return {
      userInfo: JSON.parse(localStorage.userInfo) || {},
      loading: false,
      finished: false,
      entryList: [],
      xing: ["陈", "李", "白", "周", "蔡", "卫"],
      value: "",
      noMore: true,
      rightText: "多选",
      result: [],
      checked: false,
      height: "calc(100% - 100px)",
      form:{
        empname:"",
        staname:""
      },
      noEntryList:[]
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push("/home");
    },
    onClickRight() {

    },
    onSearch() {
      var arr = searchFunc(this.value,this.form,this.noEntryList);
      if(arr.length > 0){
        this.entryList = arr;
        this.value = "";
      }else{
        Toast("检索的数据不存在");
      }
    },
    filterFunc(val, target, filterArr) {
      // 参数不存在或为空时，就相当于查询全部
      // console.log(arr);
      if (val == undefined || val == ''){
        return this.noEntryList;
      } 
      return filterArr.filter(p => {
        return p[target].indexOf(val) > -1;
      });
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      getEntryList({ empid: this.userInfo.empid }).then((res) => {
        this.entryList = res.data;
        this.noEntryList = this.entryList;
        this.loading = false;
        // 数据全部加载完成
        if (this.entryList.length >= res.data.length) {
          this.finished = true;
        }
      });
      // 加载状态结束
    },
    //添加入职信息
    gotoAdd() {
      console.log("去添加入职");
    },
    // 查看个人信息
    showPersonalInf(item) {
      console.log(item);
      this.$router.push({
        path:"/inductionInfo",
        query:{
          fromType: "待入职",
          autoid:item.autoid,
          stationid:item.stationid    
        }
      });
    },
    // 确认入职
    confirm(item) {
      // this.$router.push('/confirmEntry')
      this.$router.push({
        path: "/confirmEntry",
        query: { fromType: "待入职", autoid: item.autoid },
      });
    },
    // 全选按钮
    checkAll(checked) {
      if (checked) {
        this.$refs.checkboxGroup.toggleAll(true);
      } else {
        this.$refs.checkboxGroup.toggleAll();
      }
    },
    // 多选后 确认入职按钮
    confByChecked() {
      // this.$router.push('/interviewNotice')
      this.$router.push({
        path: "/confirmEntry",
        query: { fromType: "待入职" },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.induction {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .search {
    margin-bottom: 14px;
  }
  .add_newone {
    .van-cell__title {
      font-family: Source Han Sans CN;
      display: flex;
      align-items: center;
      .svg-icon {
        width: 2em;
        height: 2em;
        margin-right: 1em;
      }
    }
  }
  .person_cards {
    overflow: auto;
    // height: calc(100% - 300px);
    .van-checkbox-group {
      .van-checkbox {
        margin: 20px 0;
        align-items: end;
        background-color: #fff;
      }
      ::v-deep .van-checkbox__icon {
        padding-top: 0.49777rem;
        margin-left: 20px;
      }
      ::v-deep .van-checkbox__label {
        width: 94%;
        margin-left: unset;
        .van-cell {
          padding: 20px;
        }
      }
    }
    .van-cell-group::after {
      border: unset;
    }
    .van-cell-group {
      margin: 15px 0;
      .van-cell__title {
        flex: 2;
      }
      .van-cell__value {
        flex: 0.8;
      }
    }
    .cell_one {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      span {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
      .svg-icon {
        margin: 0 20px;
      }
    }
    .cell_two {
      font-family: Source Han Sans CN;
      color: #2b8df0;
      span {
        margin-right: 10px;
      }
      .svg-icon {
        font-size: 36px;
        margin: 0 18px;
      }
    }
    .cell_three {
      font-size: 28px;
      font-family: Source Han Sans CN;
      color: #999;
      .padding_sp {
        padding: 0 10px;
      }
    }
    .cell_four {
      color: #2b8df0;
      font-family: Source Han Sans CN;
    }
    .van-cell__value {
      display: flex;
      align-items: center;
      flex-direction: column;
      .head_card {
        // position: absolute;
        // top: 38px;
        // right: 30px;
        width: 106px;
        height: 106px;
        padding-bottom: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .van-button {
        border-radius: 8px;
        width: 140px;
        margin: 18px 0;
      }
    }
  }
  .footer_btn {
    width: 100%;
    padding: 0.26667rem 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    background-color: #fff;
    ::v-deep .van-checkbox__icon {
      margin-left: 20px;
    }
    ::v-deep .van-checkbox__label {
      font-size: 0.48rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }
    .van-button {
      margin-left: auto;
      margin-right: 0.60667rem;
      border-radius: 9px;
      width: 3.86667rem;
      .van-button__text {
        font-size: 0.48rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
    }
  }
}
</style>